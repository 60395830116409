.sc-hBURRC:before {
    content: "Выберите услугу или услуги:";
    font-size: 17px;
    text-align: center;
    width: 100%;
    display: block;
    padding-top: 20px;
    font-weight: 500;
    color: red;
    font-size: 20px;
}
table.sc-cxpRKc.PwmVY p {
    color: red;
}
table.sc-cxpRKc.PwmVY tr:nth-child(1) td:last-child div:after {
    content: "Выберите дату и время:";
    clear: both;
    font-size: 20px;
    padding-bottom: 20px;
    display: flex;
    color: red;
    font-size: 20px;
    font-weight: 500;
    justify-content: space-around;
}
table.sc-cxpRKc.PwmVY tr:first-child td:last-child {
    vertical-align: middle;
}
input.form-control.search-service {
    margin-top: 20px;
}